import { addDoc, collection } from "firebase/firestore";
import React, { useState } from "react";
import { db } from "../../../config/configFirebase";
import { getLangFromUrl, useTranslations } from "../../../i18n/utils";

interface FormData {
  phone: string;
  name: string;
}

export const FormVinculationPreferential = () => {
  const lang = getLangFromUrl(new URL(window.location.href));
  const t = useTranslations(lang);

  const [formData, setFormData] = useState<FormData>({
    phone: "",
    name: "",
  });
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const historialRef = collection(db, "vinculation-preferential");
      const dataToSend = { ...formData, createdAt: new Date() };
      await addDoc(historialRef, dataToSend);
      setFormData({ phone: "", name: "" });
      setSuccess(true);
      setError(null);
    } catch (error) {
      console.error("Error", error);
      setError(t("vinculationPreferential.form.errorMessage"));
      setSuccess(false);
    }
  };

  return (
    <div >
      <form
        className="flex max-w-3xl flex-col gap-x-4 p-4"
        onSubmit={handleSubmit}
      >
        {success ? (
          <div className="h-52 flex justify-center items-center">
            <p className="bg-emerald-500 text-white text-center p-2 rounded-md">
              {t("vinculationPreferential.form.successMessage")}
            </p>
          </div>
        ) : error ? (
          <div className="h-52 flex justify-center items-center">
            <p className="bg-red-500 text-white text-center p-2 rounded-md">
              {error}
            </p>
          </div>
        ) : (
          <div className="flex max-w-3xl flex-col">
            <input
              type="text"
              required
              className="min-w-0 flex-auto my-4 rounded-md border-0 bg-white/5 px-3.5 py-2 text-emerald-950 dark:text-slate-50 shadow-md ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-emerald-500 sm:text-sm sm:leading-6 bg-white"
              placeholder={t("vinculationPreferential.form.namePlaceholder")}
              value={formData.name}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
            />
            <input
              type="number"
              inputMode="numeric"
              required
              className="min-w-0 flex-auto my-4 rounded-md border-0 bg-white/5 px-3.5 py-2 text-emerald-950 dark:text-slate-50 shadow-md ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-emerald-500 sm:text-sm sm:leading-6"
              placeholder={t("vinculationPreferential.form.phonePlaceholder")}
              value={formData.phone}
              onChange={(e) =>
                setFormData({ ...formData, phone: e.target.value })
              }
            />
            <button
              type="submit"
              className={`my-4 flex-none rounded-md py-2.5 px-3.5 text-sm font-medium text-white shadow-sm bg-emerald-500 hover:bg-emerald-600"focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2`}
            >
              {t("vinculationPreferential.form.submitButton")}
            </button>
          </div>
        )}
      </form>
    </div>
  );
};
